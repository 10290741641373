import React, { Component } from 'react'
import Image from '@/components/utils/Image'
import { Line } from '@/animation'

export default class PageTitle extends Component {
	render() {
		const { title, subtitle, image } = this.props

		return (
			<div className="relative overflow-hidden">
				{image && (
					<div className="absolute pin w-full h-full dimmer dimmer--image">
						<Image
							fluid={image}
							outerWrapperClassName="h-full"
							className="h-full"
						/>
					</div>
				)}
				<div className="wrapper relative flex flex-col items-end md:items-start md:w-12/24 lg:w-auto lg:grid py-5 lg:pb-9">
					<h1 className="text-xlh-fluid font-bold relative mb-1 lowercase lg:col-start-7 lg:col-end-19">
						<Line className="absolute h-5 w-screen vert-center pin-r-100 bg-teal mr-2" />
						{title}
					</h1>

					{subtitle && (
						<div className="w-full text-center md:text-left lg:col-start-7 lg:col-end-19">
							<p className="text-lg-fluid text-grey-light lg:text-white">
								{subtitle}
							</p>
						</div>
					)}
				</div>
			</div>
		)
	}
}

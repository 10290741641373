import React, { Component, Fragment } from 'react'
import * as R from 'ramda'
import { Link } from 'gatsby'
import PageTitle from '@/components/PageTitle/PageTitle'
import SectionHeading from '@/components/SectionHeading/SectionHeading'
import PostThumb from '@/components/PostThumb/PostThumb'
import Slide from '@/components/Slide/Slide'
import { prepItem, getSeoImage } from '@/utils'
import SEO from '@/components/SEO/SEO'
import BetterIcon from '@/components/utils/BetterIcon'
import rightChevron from '@/icons/ui-right-chevron.svg'

export default class Feeds extends Component {
	render() {
		const { entry, location } = this.props

		const {
			title,
			subtitle,
			image,
			blockTitle,
			featuredContent: tmpFeaturedContent,
			blocks: tmpBlocks,
			playlistCategory
		} = entry

		const featuredContent = prepItem(tmpFeaturedContent)
		const { optimisedBanner } = image[0]
		const series = playlistCategory.length ? playlistCategory[0].uri : false

		const blocks = R.compose(
			R.map(
				({ heading, id, selectedContent, sortByDate, playlistCategory }) => {
					const rows = prepItem(selectedContent)
					const series = playlistCategory.length
						? playlistCategory[0].uri
						: false

					return {
						heading,
						id,
						series,
						rows: sortByDate
							? R.compose(
									R.reverse,
									R.sortBy(R.prop('rawDate'))
							  )(rows)
							: rows
					}
				}
			)
		)(tmpBlocks)

		const {
			socialMetaTitle,
			socialMetaDescription,
			seoTitle,
			seoDescription
		} = entry
		const seoImage = getSeoImage(entry)

		return (
			<Fragment>
				<SEO
					title={seoTitle || entry.title}
					description={seoDescription || subtitle}
					socialTitle={socialMetaTitle || entry.title}
					socialDesc={socialMetaDescription || subtitle}
					image={seoImage}
					url={location.href}
				/>
				<PageTitle title={title} subtitle={subtitle} image={optimisedBanner} />
				<div className="bg-black">
					<div className="wrapper pb-2 xl:py-4">
						<div className="lg:flex lg:flex-wrap lg:justify-start relative">
							<div className="w-full mb-2 md:w-5/24 md:mr-1/24">
								<SectionHeading title={blockTitle} />
								{series && (
									<Link
										className="text-sm-fluid font-bold hover:text-orange mt-1 focus:text-orange outline-none trans trans-color inline-flex items-center"
										to={`/${series}`}
									>
										<span className="mr-0-5">view all</span>
										<span className="text-teal">
											<BetterIcon icon={rightChevron} className="w-10" />
										</span>
									</Link>
								)}
							</div>
							<div className="w-full w-18/24 md:w-full lg:w-17/24 ">
								<Slide
									content={featuredContent}
									slidesToShow={2}
									wrapperClassName="lg:pr-3"
									render={item => <PostThumb {...item} />}
								/>
							</div>
						</div>
					</div>
				</div>
				<div>
					{blocks.map(({ heading, id, rows, series }, index) => (
						<div
							className={index % 2 === 0 ? 'bg-grey-dark' : 'bg-black'}
							key={id}
						>
							<div className="wrapper py-2 xl:py-4">
								<div className="lg:flex lg:flex-wrap lg:justify-start relative">
									<div className="w-full mb-2 md:w-5/24 md:mr-1/24">
										<SectionHeading title={heading} />
										{series && (
											<Link
												className="text-sm-fluid font-bold hover:text-orange mt-1 focus:text-orange outline-none trans trans-color inline-flex items-center"
												to={`/${series}`}
											>
												<span className="mr-0-5">view all</span>
												<span className="text-teal">
													<BetterIcon icon={rightChevron} className="w-10" />
												</span>
											</Link>
										)}
									</div>
									<div className="w-full w-18/24 md:w-full lg:w-17/24 ">
										<Slide
											content={rows}
											slidesToShow={3}
											wrapperClassName="md:w-22/24 lg:w-auto lg:pr-3"
											render={item => <PostThumb {...item} />}
										/>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</Fragment>
		)
	}
}

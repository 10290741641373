import posed from 'react-pose'

export const FadeBox = posed.div({
	entering: { opacity: 0 },
	enter: { opacity: 1, transition: { ease: 'easeOut' } },
	exit: { opacity: 0 }
})

export const Line = posed.div({
	entering: { x: '-100%' },
	enter: {
		x: 0,
		delay: 500,
		transition: {
			opacity: { ease: 'easeOut', duration: 300 }
		}
	},
	exit: { opacity: 0, x: '-100%' }
})

export const Stagger = posed.div({
	enter: {
		staggerChildren: 100
	},
	exit: {
		staggerChildren: 0
	}
})
